<template>
  <div v-html="template"></div>
</template>

<script>
import { contentTemplateAboutUs, contentStylesAboutUs } from "@/services/api/content.js";

export default {
  name: "ContentComponent",
  data() {
    return {
      template: contentTemplateAboutUs,
    };
  },
  mounted() {
    const styleTag = document.createElement('style');
    styleTag.textContent = contentStylesAboutUs;
    document.head.appendChild(styleTag);
  }
};
</script>
