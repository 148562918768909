export const contentTemplateAboutUs = `
  <div class="container content">
    <div class="row">
      <div class="col-12 text-center">
        <h4>
          <strong>ACARA</strong> es la Institución 
          <strong>que representa a todos los Concesionarios Oficiales de Automotores en la República Argentina</strong>,
          teniendo por objeto la más amplia vinculación de los asociados para difundir la función del Concesionario en la Comercialización del Automotor y fomentar
          su desarrollo en el país como factor del progreso económico y social.
        </h4>
      </div>
    </div>
  </div>
`;

export const contentStylesAboutUs = `
  .content {
    background: #ffffff;
    padding: 75px 0;
    text-align: center;
  }

  h4 {
    color: #757575;
    display: inline-block;
    font-size: 20px;
    line-height: 30px;
    width: 80%;
  }
`;
